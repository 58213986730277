<template>
  <div>
    <join-card heading="Join to Retina"  :include-name="true">
      <div class="text-red-400 w-full max-w-xs mx-auto text-center mt-4">{{ form_message }}</div>

      <form class="w-full" action="" @submit.prevent="submitForm">
        <div class="my-6 w-full">
          <input
              :type="$route.query.phone ? 'hidden':'text'"
              placeholder="Enter phone number : 017XXXXXXXX"
              v-model="userdata.phone"
              autocomplete="off"
              class="
                text-center
                block
                w-full
                max-w-xs
                mx-auto
                border-2
                rounded-lg
                focus:outline-none
                p-2
              "
          />
          <show-error :errors="formErrors" name="phone"></show-error>
        </div>
        <div class="my-6 w-full">
          <input
            type="text"
            placeholder="Enter Your Name"
            autocomplete="off"
            v-model="userdata.name"
            class="
              text-center
              block
              w-full
              max-w-xs
              mx-auto
              border-2
              rounded-lg
              focus:outline-none
              p-2
            "
          />
          <show-error :errors="formErrors" name="name"></show-error>
        </div>
        <div class="my-6 w-full">
          <input
            v-model="userdata.password"
            type="password"
            autocomplete="off"
            placeholder="Enter Your Password"
            class="
              text-center
              block
              w-full
              max-w-xs
              mx-auto
              border-2
              rounded-lg
              focus:outline-none
              p-2
            "
          />
          <show-error :errors="formErrors" name="password"></show-error>
        </div>

           <div class="my-6 w-full flex justify-center items-center gap-4 mx-auto">
          <div>
            <label>Male</label>
            <input type="radio" v-model="userdata.gender" required :value="1" name="gender" class="ml-2"/>
          </div>
          <div>
            <label>Female</label>
            <input type="radio" v-model="userdata.gender" required :value="2" name="gender" class="ml-2"/>
          </div>
        </div>
        <div class="text-center">
          <button
            type="submit"
            class="px-8 py-1 text-xl bg-blue-600 text-white rounded shadow-sm"
          >
            Confirm
          </button>
        </div>
      </form>
    </join-card>
  </div>
</template>

<script>
import JoinCard from "../components/joincard.vue";
import ShowError from "../../../../components/show-error";

export default {
  components: {ShowError, JoinCard },
  name: "Login",
  data(){
    return {
      userdata: {
        phone: this.$route.query.phone,
        name: '',
        password: '',
        gender:'',
        code: this.$route.query.otp,
      }
    }
  },

  methods: {

    submitForm( ) {

      this.formErrors = [];



      this.$store.dispatch( 'student/register',

          {
          data: this.userdata,

          error: response => this.setFormMessage( response.data.message ),

          has_errors: errors => this.setFormErrors( errors ),

          success: ( response ) => {
            const data = response.data;
            this.setFormMessage( data.message );

            if( data.success ) {
              this.setTokenAndRedirectToNext( data );
            }
          }
        }

      );

    }
  }
};
</script>

